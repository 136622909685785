#sidebar {  
    height    : 100vh;
    min-width : 55px;
    z-index   : 1;
    border-right: 1px solid $cF0F0F0;

    .partailSideNav {
        position         : relative;
        background-color : $cffffff;
        z-index          : 1;
        width            : 55px;

        .pSide{
            height : 100%;
            width  : inherit;
            border-right: 1px solid $cF0F0F0;
        }
        
        .logoutMenu{
            z-index: 1000;
            width: 180px;
            position: absolute;
            bottom: 10px;
            left: 50px;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        }

        .singleItemLogoutMenu{
            padding: 8px 0px 8px 24px;
        }

        .userProfileIcon{
            position: absolute;
            bottom: 0px;
            padding: 8px 0px;
            border-top: 0.5px solid  $cF0F0F0 !important;
            border-right: 1px solid  $cF0F0F0 !important;
            background-color: white;
        }

        .borders{
            border-bottom: 0.5px solid $cE6E6E6 !important;
        }

        .logo {
            width: inherit;
            height: 55px;
            text-align: center;
            
            // right    : 0%;
            // top      : 16px;
            // height   : fit-content;
            // left     : 22.3%;
            // width    : 54.54%;
        }
        .bar {
            position      : absolute;
            border-bottom : 0.5px solid $dividerColor !important;
            right         : 0%;
            width         : 100%;
        }
        .navPartial {
            position : absolute;
            // height   : 62.3%;
            height   : 75.3%;
            // width    : 54.5%;
            width: 100%;
            text-align: center;
            // left     : 22.7%;
            overflow-y: auto;
        }
        .topMarg {
            margin-top : 12%;
        }

        .icons {
            cursor  : pointer;
            height: 32px;

        }
        .icons:hover path{
            fill       : #2F80ED;
            transition : ease 0.3s;
        }
        .icons:hover {
            background-color : none !important;
            // border-radius    : 50px;
            transition       : ease 0.3s;
        }
        .active path{
            fill       : #2F80ED;
            transition : ease 0.3s;
        }
        .active{
            background-color: #EBF3FE;
            transition: ease 0.3s;
            width: 32px;
            border-radius: 8px;
        }

        .active svg path{
            fill       : #2F80ED !important;
        }

        .profile {
            position : absolute;
            bottom   : 1.8%;
            left     : 21.5%;
        }
    }

    .sideBarMain {
        position         : relative;
        background-color : $cffffff;
        width            : 270px;
        // width           : 330px;
        transition       : all .3s ease;


        .sidebarHeading{
            height: 59px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 16px;
            font-size: 20px;
            font-weight: 500;
        }

        .paragraphText {
            font-size   : 14px;
            font-weight : normal;
            color       : $cAFAEAE
        }

        .active .paragraphText {
            font-size   : 14px;
            font-weight : normal;
            color       : $c1AC4D1;
        }
        .bar {
            position      : absolute;
            border-bottom : 0.5px solid $dividerColor !important;
            right         : 0%;
            top           : 59px;
            width         : 100%;
        }

        a{
            text-decoration: none !important;
        }

        .item {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
        }

        .subItem {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 20px;
        }

        .subMenuSelected{
            color: $themeColor;
            font-weight: 500;
            opacity: 1;
        }

        .item:hover {
            text-decoration  : none !important;
            opacity: 1;
            color: $brandingPrimaryColor !important;
        }
        .item:focus {
            text-decoration  : none !important;
            opacity: 1;
            color: $brandingPrimaryColor;
            background-color : $navigationSelectedBg;
        }
        .active{
            color           : $brandingPrimaryColor !important;
            opacity         : 1;
            background-color : $navigationSelectedBg;
            transition      : ease 0.3s;
            border-radius   : 4px;
        }

        .active path{
            fill: $subtitle !important;
            opacity: 1;
        }
        
        .filters {
            border-top : 0.5px solid $cE0E0E0;
        }

        .circle1 {
            width            : 35px !important;
            height           : 35px !important;
            border-radius    : 35px;
            background-color : $cffffff;
        }
        .circle2 {
            width            : 35px !important;
            height           : 35px !important;
            border-radius    : 35px;
            background-color : $cffffff;
        }
        .img {
            margin : 8px;
        }
        .circle {
            width         : 35px;
            height        : 35px;
            border-radius : 50%;
        }

        .circle1:hover{
            box-shadow : 0px 4px 8px rgba(133, 133, 133, 0.2);

            cursor        : pointer;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }
        .circle2:hover{
            box-shadow    : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor        : pointer;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }
        .circle:hover{
            box-shadow    : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor        : pointer;
            width         : 35px;
            height        : 35px;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }

        .walletlyHome {
            margin-top : 32px;
        }
        .headingH2 {
            margin-top : 33px;
        }
        .searchIcon {
            float        : right;
            margin-right : 34px;
        }
    }
    .hideSubNav {
        margin-left : -220px;
        transition  : all .3s ease;
    }


    .tooglecircle{
       display : none;
    }
}

@media (max-width: 767px){
    #sidebar {
        .tooglecircle{
            display          : inline;
            z-index          : 1;
            width            : 50px;
            height           : 50px;
            border-radius    : 50px;
            position         : fixed;
            bottom           : 7%;
            right            : 10%;
            background-color : $c333333;
        }
        .hidePartial { 
            margin-left : -55px;
            transition  : all .4s ease;
        }

        .partailSideNav {
            transition : all .4s ease;

            .brandLogo {
                position : absolute;
                right    : 0%;
                top      : 16px;
                height   : 5.5%;
                left     : 22.3%;
                width    : 54.54%;
            }
            .navPartial {
                position : absolute;
                top      : 88px;
                height   : 62.3%;
                width    : 54.5%;
                left     : 22.7%;
            }
        }
        .hideSubNav {
            display : none;
        }

        .overlay {
            height     : 100%;
            width      : 100%;
            position   : fixed;
            background : #000000;
            z-index    : 100;
            opacity    : 0.6;
        }
    }
  }


  @media (min-width: 1500px){ 

    .partailSideNav {
        transition : all .4s ease;

        .brandLogo {
            position : absolute;
            right    : 0%;
            top      : 16px;
            height   : 5.5%;
            left     : 22.3%;
            width    : 54.54%;
        }
        .navPartial {
            position : absolute;
            // top      : 88px;
            height   : 62.3%;
            width    : 54.5%;
            // left     : 22.7%;
        }
    }
  }

  @media (min-width: 800px) and (max-width: 1300px){ 

    .partailSideNav {
        transition : all .4s ease;

        .brandLogo {
            position : absolute;
            right    : 0%;
            top      : 16px !important;
            height   : 5.5%;
            left     : 22.3%;
            width    : 54.54%;
        }
        .navPartial {
            position : absolute;
            height   : 62.3%;
            width    : 54.5%;
        }
    }

    .sideBarMain{
        .headingH2{
            margin-top : 8% !important;
        }
    }
  }





