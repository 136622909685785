#SignupHeader{
    max-height: 70px;
    border-bottom: 0.5px solid $cE6E6E6;
    padding: 9px 27px 9px 27px; 
    margin-right: 0px;

    .logoBox{
        text-align: right;
    }

    .buttonBox{
        text-align: left;
        display: flex;
        float: right;
        justify-content: flex-end;
        padding-top: 4px;

    }

    .contents{
        padding-top: 6px;
    }

    .captions{
        float: left;
    }

    .caption2{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;

        
    }

    .loginButton:hover{
        background-color: $buttonHoverColor;
    }


    #firstText{
        color: $placeholder;
    }

    .loginButton{
        background-color: $c2F80ED;
        color: white;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.5px;
        padding: 12px 8px;
        width: 112px;
        height: 48px;
        border-radius: 8px;
        text-decoration: none;
    }

    #secondText{
        margin-top: -8px;
    }

    .textButton{
        padding-right: 0px;
        margin-right: -40px;
        
    }


    @media screen and (max-width: 812px) {
        
        .logoBox{
            display: none;
        }

        #firstText{
            display: none;
        }

        .textButton{
            margin-right: 0px;
        }

        #cardEnclosed{
            margin-left: 5px;
        }
    }
}