#LookAndFeel{
    .boxBorder{
        border: 1px solid #DFE1E6;
        border-radius: 4px;
        width: 90px;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .innerBoxBorder{
        width: 60px;
        height: 60px;
    }
    .innerBoxBorderFavicon{
        width: 16px;
        height: 16px;
    }
    .innerBoxBorderLoader{
        width: 32px;
        height: 32px;
    }
    .textbox{
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        border: 1px solid #DFE1E6;
        border-radius: 4px;
        width: 240px;
        height: 40px;
    }
    .contentEditable{
        width: inherit;
    }
}