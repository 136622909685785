#General{
    .MuiCheckbox-colorSecondary.Mui-checked{
        color: #2F80ED !important;
    }
    .MuiCheckbox-colorSecondary.Mui-checked:hover{
        background-color: inherit !important;
    }
    .MuiIconButton-colorSecondary:hover{
        background-color: inherit !important;
    }
    .cke_chrome{
        border: none !important;
        height: 100vh;
    }
    .cke_top{
        background-color: #FFFFFF !important;
        border-bottom: none !important;
    }
    .MuiListItemIcon-root{
        margin: 0px;
    }
    .textColor-a{
        color: #000000
    }
    .textColor{
        color: #B2B2CC
    }
    .text{
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0.10000000149011612px;
    }
    .MuiTypography-body1{
        color: '#000000' !important;
        font-family: 'Poppins' !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 30px !important;
        letter-spacing: 0px !important;
        position: absolute;
        left: 16px;
    }
    .MuiIconButton-colorSecondary{
        color: #DFE1E6 !important
    }
    .parentLi{
        margin-bottom: 8px;
        margin-right: 8px;
        cursor: pointer;
    }
    .parentLi-a{
        background-color: #ECF4FD;
        border-radius: 4px;
        margin-bottom: 8px;
        margin-right: 8px;
        cursor: pointer;
    }
    .PrivateSwitchBase-root-7{
        padding: 0px !important;
        margin-right: 4px !important;
    }
    .MuiListItem-gutters{
        padding-right: 0px !important;
    }
    #cke_1_contents{
        height: 90vh !important;
    }
    .cke_top{
        border-bottom: 1px solid #F0F0F0 !important;
    }
    .cke_editable{
        height: 100vh !important;
    }
    .MuiSwitch-thumb{
        margin-top: 1px;
    }
    .cke_bottom{
        display: none;
    }

    #Api{
        padding-right: 32px;
        .apiBox{
            padding: 24px 10px 24px 10px;
            border: 1px solid #E6E6E6;
            border-radius: 4px;
            margin-top: 24px;
        }

        .bar{
            background-color : #E6E6E6;
            height: 1px;
        }

        .detail{
            line-height: 20px;
            letter-spacing: 0.25px;
            white-space: pre-line !important;
        }

        .planName{
            height: 32px;
            padding: 8px 16px;
            border-radius: 8px;
        }


        @media(max-width: 767px){
            .apiBox, .apiBoxComponents{
                flex-direction: column;
            }
            
            .detail, .middleBox{
                margin-top: 20px;
                padding: 0px !important;
            }

            .buttonDelete{
                text-align: left !important;
            }
        }
    }
}