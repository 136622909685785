#settingHeader{
    width: inherit;
    position: fixed;
    top: 0px;
    background-color: $whiteColor;
    z-index: 2000;
    height: inherit;
    width: calc(100% - 272px);

    .banner{
        height           : 47px;
        background-color : #2F80ED;
        display          : flex;
        flex-direction   : row;
    }

    .headerItems{
        padding: 16px;
    }

    .brand{
        border: 0px;
    }
    
    @media(max-width: 767px){
        .headerItems{
            justify-content: flex-end;
        }
    }
}