#setting, #profile{
    .component{
        padding: 24px 0px 24px 32px;
        width: 100%;
        top: 50px;
        
    }


}

#integration{
    .bar{
        background-color : #E6E6E6;
        height: 1px;
    }

    .icon{
        height: fit-content;
    }
}


#BrandListing{

    .showSearch{
        width: 30px;
    }

    .searchIcon{
        position: absolute;
        top: 8px;
        right: 4px;
        z-index: 100;
    }
}

#CustomPlan{
    .stripe-div{
        width: 269px;
        height: 247px;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        border-radius: 4px;
        padding: 16px;
    }
    .stripe-div:hover{
        box-shadow: 0px 10px 58px -33px #9494B8;
        transition: 0.20s ease-in;
    }
    .stripe-img{
        height: 40px;
        width: 40px;
    }
}

#Plans, #Languages{
    
    .bar-full{
        width: 102%;
        margin-left: -32px;
    }
    .hover-div:hover{
        background-color: $hoverColor;
    }

    .list-scroll{
        height: 70vh;
        overflow-y: scroll;
    }

    .more-hover{
        background-color: $buttonTertiaryColor;
        padding: 5px;
        border-radius: 2px;
    }
    .more-hover:hover{
        background-color: $textFieldBorderDefaultColor;
        padding: 5px;
        border-radius: 2px;
    }
}

#LanguageModal{
    .modal-list-div{
        border-radius: 4px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 20px;
    }
    .language-modal-div{
        border: 1px solid $dividerColor;
        height: 40vh;
        overflow-y: scroll;
    }
}

#ProfilePlans{
    .card{
        border: 1px solid $dividerColor;
        height: 650px;
    }
}

#PlanUpgrade{
    .stripeCardLabel{
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;
        padding-left: 8px;
        padding-bottom: 4px;
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        transform: translate(0, 1.5px) scale(0.75);
        transform-origin: top left;
        color: rgba(0, 0, 0, 0.54);
        line-height: 1;
        letter-spacing: 0.00938em;
        display: inline-block;
        margin-bottom: 5px;
        max-width: 100%;
    }
    .stripeCardElement{
        padding: 13px 16px;
        border: 1px solid rgb(227, 226, 226);
        border-radius: 8px;
        &:hover{
            border: 1px solid #2F80ED!important;
            background-color: #ffffff;
        }
    }
    .creditCardNumber{
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 50px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 2px;
    }
    .cardOption{
        padding: 15px 20px;
        right: 45px;
        bottom: -45px;
        color: $textfieldColor;
        &:hover{
            background-color: $cF6F6F9;
        }
    }
    .closeIcon{
        position: fixed;
        right: 30px;
    }
    .card{
        border: 1px solid #1274E0;
        width: fit-content;
        border-radius: 4px;
    }
    .modal-button-cancel{
        text-decoration: underline;
        padding: 6px 12px 6px 12px;
        width: fit-content;
    }
    .modal-button-cancel:hover{
        width: fit-content;
        text-decoration: underline;
        padding: 6px 12px 6px 12px;
        color: #091E42 !important;
    }
}

#PlanModal{
    .modal-button{
        border: 0.5px solid #2F80ED;
        padding: 6px 12px 6px 12px;
    }
    .modal-button-cancel{
        text-decoration: underline;
        padding: 6px 12px 6px 12px;
    }
    .modal-button-cancel:hover{
        text-decoration: underline;
        padding: 6px 12px 6px 12px;
        color: #091E42 !important;
    }
    .modal-button:hover{
        background-color: #F6F9FE !important;
    }
    
}

#CustomPlanProps{
    .modal-button-cancel{
        text-decoration: underline;
        padding: 6px 12px 6px 12px;
    }
    .modal-button-cancel:hover{
        text-decoration: underline;
        padding: 6px 12px 6px 12px;
        color: #091E42 !important;
    }
}

#PlansProps.button{
        border: 0.5px solid #2F80ED;
    }


#BillingHistory {
    .scroll{
        overflow-y: scroll;
        height: 80vh !important;
    }
    .card{
        border: 1px solid $dividerColor;
    }
    .button{
        border: 0.5px solid #2F80ED;
    }
}


#BillingHistory{
    .scroll{
        overflow-y: scroll;
        height: 85vh !important;
    }
    .empty-screen{
        text-align: center;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .stripeCardLabel{
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;
        padding-left: 8px;
        padding-bottom: 4px;
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        transform: translate(0, 1.5px) scale(0.75);
        transform-origin: top left;
        color: rgba(0, 0, 0, 0.54);
        line-height: 1;
        letter-spacing: 0.00938em;
        display: inline-block;
        margin-bottom: 5px;
        max-width: 100%;
    }
    .stripeCardElement{
        padding: 13px 16px;
        border: 1px solid rgb(227, 226, 226);
        border-radius: 8px;
        &:hover{
            border: 1px solid #2F80ED!important;
            background-color: #ffffff;
        }
    }
    .creditCardNumber{
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 50px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 2px;
    }
    .cardOption{
        padding: 15px 20px;
        right: 45px;
        bottom: -45px;
        color: $textfieldColor;
        &:hover{
            background-color: $cF6F6F9;
        }
    }

}