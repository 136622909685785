#barcode{
    .barcodeDiv{
        width: max-content;
        padding: 32px;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10.5974px;
        margin: auto;
        width: 33.82%;
        height: 49%;
        max-width: 450px;
        max-height: 450px;
        margin-top: 76px;
    }
    .qrcode {
        border-radius: 15px;
        border: 1px solid $cB0B0B0;
        width: 100%;
    }
    .fieldDiv {
        width: 50.4%;
        margin: auto;
        margin-top: 44px;
        padding-bottom: 140px;
    }
}   

#cameraScan{
    .cameraDiv{
        width: max-content;
        padding: 32px;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10.5974px;
        margin: auto;
        width: 60%;
        height: 70.9%;
        margin-top: 5%;
        max-width: 608px;
        max-height: 608px;
    }
}

#codeinput {
    .codeinputDiv{
        max-width: 720px;
        min-width: 640px;
        height: 56%;
        max-height: 442px;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10.5974px;
        margin: auto;
        margin-top: 10%;
        padding: 72px 50px;
    }
    .paragraphsm {
        font-size: 16px;
    }
    .boxes {
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .paragraphsm {
            font-size: 14px;
            color: $c262626;
            font-weight: normal;
        }
    }
    .col-2 {
        flex: 0 0 17%;
        max-width: 17%;
    }
    .row{
        grid-gap: 15px;
        text-align: center;
    }
    .code {
        width: 100%;
    }

    .boxes:hover{
        box-shadow: inset -4.08333px -4.08333px 4.08333px rgba(255, 255, 255, 0.7), inset 6.125px 6.125px 4.08333px rgba(174, 174, 192, 0.2);
        border-radius: 10px;
        cursor: pointer;
    }
    .active{
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10px;
        border: 1px solid #1AC4D1;
    }
}

#customerinfo{

    .codeinputDiv{
        max-width: 720px;
        min-width: 640px;
        height: 56%;
        max-height: 506px;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10.5974px;
        margin: auto;
        margin-top: 9%;
        padding: 72px 50px;
    }
    
    .paragraphsm {
        font-size: 16px;
    }
    .boxes {
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10px;
        height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .paragraphsm {
            font-size: 14px;
            color: $c262626;
            font-weight: normal;
        }
    }
    .col-3 {
        flex: 0 0 30%;
        max-width: 30%;
    }
    .row{
        text-align: center;
    }
    .code {
        width: 100%;
    }

    .boxes:hover{
        box-shadow: inset -4.08333px -4.08333px 4.08333px rgba(255, 255, 255, 0.7), inset 6.125px 6.125px 4.08333px rgba(174, 174, 192, 0.2);
        border-radius: 10px;
        cursor: pointer;
    }
    .active{
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 10px;
        border: 1px solid #1AC4D1;
    }

}