.header {
    max-height: 420px;
    height: 420px;
    position: relative;
    // backgroundImage: url("/icons-logos/headertexture.png");

    .headerRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .profile {
        margin-right: 16px;
        margin-top: 18px;
    }

    .togglebtn {
        position: fixed;
        bottom: 16px;
        left: 24px;
    }

    .headerMainHead {
        margin-left: 40px;
        margin-top: 6vh;
    }

    .subtitle1 {
        color : $cffffff;
    }

    .brand {
        cursor: pointer;
        background: none;
        border: none;
        color: $cffffff;
        opacity: 1;
        margin-left: 16px;
        margin-top: 24px;
    }

    .vectorImg {
        height: 100%;
        width: 100%;
        margin-top: -25px;
    }

    .Link , .Link:hover , .buttonText , buttonText:hover{
        text-decoration: none;
    }
}