#audience {
    .component{
        width: 100%;
        top: 60px;
        
    }
    .brandlisting {
        border-right: 1px solid #F0F0F0;
    }


    #BrandDetails{
        .logo{
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }
        .active{
            height: 24px;
            border-radius: 2px;
        }

       
        #Analytics{
            .customerGraph, .revenueGraph, .rightContents{
                padding: 24px 24px 0px 24px;
                border: 1px solid $dividerColor;
                border-radius: 4px;
            }

            .customerGraph, .revenueGraph{
                height: 50vh;
            }

            .mainComponent{
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .leftGraphs{
                width: 60%;
            }
            .rightContents{
                width: 40%;
            }
            .viewButton{
                padding: 0px;
                width: fit-content;
                outline: 0px;
                color: $brandingPrimaryColor;
            }
        }
    }

    #PaymentDetail{
        .scroll{
            height: 59vh;
            overflow-y: scroll;
        }
    }

    #CustomerDetail{
        .scroll{
            height: 58vh;
            overflow-y: scroll;
        }
        .search{
            width: 537px;
        }
    }

    #PaymentDetail{
        height: 80vh;
        .scroll{
            height: 59vh;
            overflow-y: scroll;
        }
    }

    #CustomerDetail{
        height: 80vh;
        .scroll{
            height: 58vh;
            overflow-y: scroll;
        }
        .search{
            width: 537px;
        }
    }

    #BrandListing  {
        .listing{
            height: 80vh;
            overflow-y: scroll;
        }
        .head {
            height: 72px;
            position: relative;
            border-bottom: 1px solid #F0F0F0;

            .subtitle2 {
                font-weight: 400;
                opacity: 1;
            }

            .normalDropdown {
                right: 0;
                top: 52px;
                cursor: pointer;
                width: 140px;
                height: 72px;
                position: absolute;
                z-index: 1;
                box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
                box-sizing: border-box;
                border: 1px solid #F0F0F0;
                background-color: #ffffff;

                .option:hover{
                    background-color: #ECF4FD;
                }
            }

            .search {
                path{
                    fill: #7A869A;
                }
            }

        }
        .brand {
            height: 72px;
            border-bottom: 1px solid #F0F0F0;

            .brandimg {
                border-radius: 50%;
            }
            .subtitle2 {
                opacity: 1;
            }
        }
        .brand:hover{
            background-color: #ECF4FD;
        }

        .activeBrand{
            background-color: #ECF4FD;
        }
    }


}
