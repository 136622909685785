// #customTagTextArea{
    .tagify {
        --tags-border-color: #DDD;
        --tag-bg: #E5E5E5;
        --tag-hover: #D3E2E2;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        border: 1px solid #ddd;
        border: 1px solid var(--tags-border-color);
        padding: 0;
        line-height: 1.1;
        cursor: text;
        position: relative;
        transition: .1s
    }
    
    @keyframes tags--bump {
        30% {
            transform: scale(1.2)
        }
    }
    
    .tagify:hover {
        border-color: #ccc
    }
    
    .tagify.tagify--focus {
        border-color: #85c8ea
    }
    
    .tagify[readonly] {
        cursor: default
    }
    
    .tagify[readonly]>.tagify__input {
        visibility: hidden;
        width: 0;
        margin: 5px 0
    }
    
    .tagify[readonly] .tagify__tag__removeBtn {
        display: none
    }
    
    .tagify[readonly] .tagify__tag>div {
        padding: .3em .5em
    }
    
    .tagify[readonly] .tagify__tag>div::before {
        background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
        box-shadow: none;
        filter: brightness(.95)
    }
    
    .tagify+input,
    .tagify+textarea {
        display: none!important
    }
    
    .tagify__tag {
        display: inline-block;
        margin: 5px 0 5px 5px;
        position: relative;
        z-index: 1;
        cursor: default;
        transition: .13s ease-out
    }
    
    .tagify__tag.tagify--editable>div::before {
        box-shadow: 0 0 0 2px #d3e2e2 inset!important;
        box-shadow: 0 0 0 2px var(--tag-hover) inset!important
    }
    
    .tagify__tag.tagify--editable.tagify--invalid>div::before {
        box-shadow: 0 0 0 2px #d39494 inset!important
    }
    
    .tagify__tag>div {
        vertical-align: top;
        position: relative;
        box-sizing: border-box;
        max-width: 100%;
        padding: .3em .5em;
        color: #000;
        line-height: inherit;
        border-radius: 3px;
        -webkit-user-select: none;
        user-select: none;
        transition: .13s ease-out;
        padding-right: 1.5em
    }
    
    .tagify__tag>div>* {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        min-width: 10px
    }
    
    .tagify__tag>div>[contenteditable] {
        outline: 0;
        -webkit-user-select: text;
        user-select: text;
        cursor: text;
        margin: -2px;
        padding: 2px
    }
    
    .tagify__tag>div::before {
        content: '';
        position: absolute;
        border-radius: inherit;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 0 1.1em #e5e5e5 inset;
        box-shadow: 0 0 0 1.1em var(--tag-bg) inset;
        z-index: -1;
        pointer-events: none;
        transition: 120ms ease;
        animation: tags--bump .3s ease-out 1
    }
    
    .tagify__tag:hover:not([readonly]) div::before {
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        box-shadow: 0 0 0 1.1em #d3e2e2 inset;
        box-shadow: 0 0 0 1.1em var(--tag-hover) inset
    }
    
    .tagify__tag.tagify--noAnim {
        animation: none
    }
    
    .tagify__tag.tagify--hide {
        width: 0!important;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        opacity: 0;
        transform: scale(0);
        transition: .3s;
        pointer-events: none
    }
    
    .tagify__tag.tagify--mark div::before {
        animation: none
    }
    
    .tagify__tag.tagify--notAllowed div>span {
        opacity: .5
    }
    
    .tagify__tag.tagify--notAllowed div::before {
        box-shadow: 0 0 0 1.1em rgba(211, 148, 148, .44) inset!important;
        transition: .2s
    }
    
    .tagify__tag[readonly] .tagify__tag__removeBtn {
        display: none
    }
    
    .tagify__tag[readonly]>div {
        padding: .3em .5em
    }
    
    .tagify__tag[readonly]>div::before {
        background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
        box-shadow: none;
        filter: brightness(.95)
    }
    
    .tagify__tag__removeBtn {
        font: 14px/16px Serif;
        width: 14px;
        height: 14px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        z-index: 1;
        right: calc(.5em - 2px);
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        transition: .2s ease-out
    }
    
    .tagify__tag__removeBtn::after {
        content: "\00D7"
    }
    
    .tagify__tag__removeBtn:hover {
        color: #fff;
        background: #c77777
    }
    
    .tagify__tag__removeBtn:hover+div>span {
        opacity: .5
    }
    
    .tagify__tag__removeBtn:hover+div::before {
        box-shadow: 0 0 0 1.1em rgba(211, 148, 148, .3) inset!important;
        transition: .2s
    }
    
    .tagify:not(.tagify--mix) .tagify__input {
        white-space: nowrap
    }
    
    .tagify:not(.tagify--mix) .tagify__input br {
        display: none
    }
    
    .tagify:not(.tagify--mix) .tagify__input * {
        display: inline;
        white-space: nowrap
    }
    
    .tagify__input {
        display: block;
        min-width: 110px;
        margin: 5px;
        padding: .3em .5em;
        line-height: inherit;
        position: relative
    }
    
    .tagify__input::before {
        display: inline-block;
        width: 0
    }
    
    .tagify__input:empty::before {
        transition: .2s ease-out;
        opacity: .5;
        transform: none;
        width: auto
    }
    
    .tagify__input:focus {
        outline: 0
    }
    
    .tagify__input:focus::before {
        transition: .2s ease-out;
        opacity: 0;
        transform: translatex(6px)
    }
    
    @supports (-moz-appearance:none) {
        .tagify__input:focus::before {
            display: none
        }
    }
    
    .tagify__input:focus:empty::before {
        transition: .2s ease-out;
        opacity: .3;
        transform: none
    }
    
    @supports (-moz-appearance:none) {
        .tagify__input:focus:empty::before {
            display: inline-block
        }
    }
    
    .tagify__input::before {
        content: attr(data-placeholder);
        line-height: 1.8;
        position: absolute;
        top: 0;
        z-index: 1;
        color: #000;
        white-space: nowrap;
        pointer-events: none;
        opacity: 0
    }
    
    @supports (-moz-appearance:none) {
        .tagify__input::before {
            line-height: inherit;
            position: relative
        }
    }
    
    .tagify__input::after {
        content: attr(data-suggest);
        color: #000;
        opacity: .3;
        pointer-events: none
    }
    
    .tagify__input .tagify__tag>div {
        padding-top: 0;
        padding-bottom: 0
    }
    
    .tagify__input .tagify__tag>div::before {
        top: -3px;
        bottom: -3px
    }
    
    .tagify__input .tagify__tag:hover:not([readonly])>div::before {
        top: -3px;
        bottom: -3px;
        left: 0;
        right: 0
    }
    
    .tagify--mix .tagify__input {
        padding: 5px;
        margin: 0;
        width: 100%;
        height: 100%;
        line-height: 1.7
    }
    
    .tagify--select::after {
        content: '>';
        opacity: .5;
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        font: 16px monospace;
        line-height: 8px;
        height: 8px;
        pointer-events: none;
        transform: translate(-150%, -50%) scaleX(1.2) rotate(90deg);
        transition: .2s ease-in-out
    }
    
    .tagify--select[aria-expanded=true]::after {
        transform: translate(-150%, -50%) rotate(270deg) scaleY(1.2)
    }
    
    .tagify--select .tagify__tag {
        position: absolute;
        top: 0;
        right: 1.8em;
        bottom: 0
    }
    
    .tagify--select .tagify__tag div {
        display: none
    }
    
    .tagify--select .tagify__input {
        width: 100%
    }
    
    .tagify--invalid {
        --tags-border-color: #D39494
    }
    
    .tagify__dropdown {
        position: absolute;
        z-index: 9999;
        background: #fff;
        max-height: 300px;
        overflow: auto;
        border: 1px solid #85c8ea;
        box-shadow: 0 2px 4px -2px rgba(0, 0, 0, .2);
        box-sizing: border-box
    }
    
    .tagify__dropdown__item {
        box-sizing: inherit;
        padding: .35em .6em;
        margin: 2px;
        cursor: pointer;
        border-radius: 3px;
        position: relative;
        outline: 0
    }
    
    .tagify__dropdown__item--active {
        background: #e5e5e5
    }
    
    .tagify__dropdown__item:active {
        filter: brightness(105%)
    }
// }