$colors: (
    'cAFAEAE'                     : $cAFAEAE,
    'c333333'                     : $c333333,
    'c222222'                     : $c222222,
    'cEB763C'                     : $cEB763C,
    'cffffff'                     : $cffffff,
    'c000000'                     : $c000000,
    'c828282'                     : $c828282,
    'c4F4F4F'                     : $c4F4F4F,
    'cE0E0E0'                     : $cE0E0E0,
    'c459B95'                     : $c459B95,
    'cE8F3F2'                     : $cE8F3F2,
    'cF2F2F2'                     : $cF2F2F2,
    'cF7F6F4'                     : $cF7F6F4,
    'cFDEFE8'                     : $cFDEFE8,
    'cF7F7F7'                     : $cF7F7F7,
    'cFAFAFA'                     : $cFAFAFA,
    'cE4E4E4'                     : $cE4E4E4,
    'c1AC4D1'                     : $c1AC4D1,
    'c717171'                     : $c717171,
    'cF6FDFE'                     : $cF6FDFE,
    'c262626'                     : $c262626,
    'cB0B0B0'                     : $cB0B0B0,
    'c1D3B56'                     : $c1D3B56,
    'cF6F6F7'                     : $cF6F6F7,
    'c486381'                     : $c486381,
    'cE7404A'                     : $cE7404A,
    'cFA7C49'                     : $cFA7C49,
    'cFF005C'                     : $cFF005C,
    'cFFCCDE'                     : $cFFCCDE,
    'cF6F6F9'                     : $cF6F6F9,
    'c3D0A65'                     : $c3D0A65,
    'cBDBDBD'                     : $cBDBDBD,
    'c2D9CDB'                     : $c2D9CDB,
    'cE6E6E6'                     : $cE6E6E6,
    'c2E3F52'                     : $c2E3F52,
    'c14142B'                     : $c14142B,
    'cEFF0F6'                     : $cEFF0F6,
    'c6E7191'                     : $c6E7191,
    'c4DCC6C'                     : $c4DCC6C,
    'c47B005'                     : $c47B005,
    'c191919'                     : $c191919,
    'c062A32'                     : $c062A32,
    'cF8FAFB'                     : $cF8FAFB,
    'c289CCF'                     : $c289CCF,
    
    'headline3'                   : $headline3,
    'Secondary'                   : $Secondary,
    'subtitle'                    : $subtitle,
    'placeholder'                 : $placeholder,
    'caption'                     : $caption,
    'automationHeaderColor'       : $automationHeaderColor,
    'loyaltyCardHeaderColor'      : $loyaltyCardHeaderColor,
    'punchPassesHeaderColor'      : $punchPassesHeaderColor,
    'subscriptionHeaderColor'     : $subscriptionHeaderColor,
    'coupansHeaderColor'          : $coupansHeaderColor,
    'eventsHeaderColor'           : $eventsHeaderColor,
    'scratchEventsHeaderColor'    : $scratchEventsHeaderColor,
    'customerEmailHeaderColor'    : $customerEmailHeaderColor,
    'redemptionHeaderColor'       : $redemptionHeaderColor,
    'referralCampaignHeaderColor' : $referralCampaignHeaderColor,
    // 'buttonHoverColor'            : $buttonHoverColor,
    'linkContact'                 : $linkContact,
    'buttonTextColor'             : $buttonTextColor,
    'buttonBackgroundColor'       : $buttonBackgroundColor,
    'themeColor'                  : $themeColor,
    'tableBackgroundColor'        : $tableBackgroundColor,
    'questionColor'               : $questionColor,
    'tableActionDropdownColor'    : $tableActionDropdownColor,
    'tableSearchBoxColor'         : $tableSearchBoxColor,
    'error'                       : $error,
    'unlimitedPlanColor'          : $unlimitedPlanColor,
    'unlimitedPanColorHover'      : $unlimitedPlanColorHover,
    'deleteButtonColor'           : $deleteButtonColor,
    'textfieldColor'              : $textfieldColor,
    'textFieldBorderColor'        : $textFieldBorderColor,
    'caption2Color'               : $caption2Color,
    'headingColor'                : $headingColor,
    'returnToPro'                 : $returnToPro,
    'returnToProHover'            : $returnToProHover,
    'paragraphInputColor'         : $paragraphInputColor,
    'activeColor'                 : $activeColor,
    'selectGreenBackgroundColor'  : $selectGreenBackgroundColor,
    'accordionBorderColor'        : $accordionBorderColor,
    'accordiansidebarValue'       : $accordiansidebarValue,
    'redColorButton'              : $redColorButton,


    //new Colors Defined

    'blackColor'                 : $blackColor,
    'whiteColor'                 : $whiteColor,
    'navigationUnSelected'       : $navigationUnSelected,
    'navgiationTextSelected'     : $navgiationTextSelected,
    'navigationSelectedBg'       : $navigationSelectedBg,
    'brandingPrimaryColor'       : $brandingPrimaryColor,
    'textFieldLabelColor'        : $textFieldLabelColor,
    'textFieldPlaceHolderColor'  : $textFieldPlaceHolderColor,
    'textFieldTextActiveColor'   : $textFieldTextActiveColor,
    'textFieldBorderDefaultColor': $textFieldBorderDefaultColor,
    'textFieldBorderHoverColor'  : $textFieldBorderHoverColor,
    'iconPrimaryColor'           : $iconPrimaryColor,
    'buttonTertiaryColor'        : $buttonTertiaryColor,
    'buttonHoverColor'           : $buttonHoverColor,
    'hoverColor'                 : $hoverColor,
    'dividerColor'               : $dividerColor,
    
    'disableButtonColor'         : $disableButtonColor,
    'green2'                     : $green2
    



);

@each $name, $color in $colors {
    .color-#{$name} {
        color : #{$color} !important;
    }

    .bg-color-#{$name} {
        background-color : #{$color} !important;
    }
}

