$sizeamount: (
    5,
    10,
    15,
    16,
    20,
    25,
    30,
    31,
    32,
    33,
    35,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    65,
    70,
    75,
    80,
    85,
    90,
    95,
    96,
    97,
    98,
    99,
    100,
); // Adjust this to include the pixel amounts you need.

@each $size in $sizeamount {
    .w-#{$size} {
        // width: #{$size}% !important;
        width: #{$size + "% !important"};
    }
}

