#Imagedropbox{

    .coverImageDropZone{
        border: 1px dashed $themeColor;
        border-radius: 8px;
        height: 113px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    
}