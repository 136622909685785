

.OnBoardingPage{

    .header {
        width: 100%;
        position: fixed;
        z-index: 9999;
        top: 0px;
        height: 70px;
        background-color: white;
    }

    .bar{
        background: linear-gradient(90deg, #1367D8 0%, #5F9EF1 100%);  
        z-index: 1;
        -webkit-transition : width 0.7s ease;
        -moz-transition : width 0.7s ease;
          -o-transition : width 0.7s ease;
             transition : width 0.7s ease;
    }

    .dashboard {
        display: none !important;
    }

    .btn-next {
        float: right;
    }

    #logo {
        border-radius: 50%;

    }
}

.progressBar{
    width : 100%;
    height : 4px;
    background-color: lightgray;
}



#connectBrand{ 
    width: 100%;
    position: absolute;
    background-color: $cffffff;
    padding: 0;
    z-index: 999;
    transition: transform 0.8s;

    .cardMain {
        width: 80%;
        margin: auto;
    }

    .cardsRow {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: row;
    }

    .cardsSel {
        position: relative;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 8px;
        width: 251px;
        height: 250px;
        margin: auto;
        border: 1px solid #747474;
    }

    .coverimage{
        width: 100%;
        background-color:lightgray;
        position :absolute;
        top: 0px;
        height: 100px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
    }

    .cards {
        position: relative;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 8px;
        width: 250px;
        height: 250px;
        margin: auto;
    }

    .circle {
        width: 80px;
        height: 80px;
        margin: auto;
        background-color: $cF2F2F2;
        border-radius: 50%;
        position: relative;
    }

    .selected {
        position: absolute;
        top : 8px;
        padding: 4px 6.5px;
        border-radius: 50%;
        right: 8px;
    }

    .toolbar {
        display: none;
    }

    .subHeader{
        display: none;
    }
    .brands{
        display: none;
    }

    .input {    
        width: 100px;
        height: 25px;
        margin: auto;
        position: absolute;
        top: 155px;
        left: 76px;
        text-align: center;
    }


}

.scale {
    transform: translate(35%, 76px);
    background: none;
    z-index: -1 !important;
    overflow-x: hidden;
    
    &.map{
        transform: translate(55%, 76px);
        z-index: 999 !important;
    }

    .subHeader{

        width: 97%;
        margin: auto;
        display: block !important;
    }
    .brands {
        margin-left: 30px !important;
        display: block !important;
    }

    .cardsRow {
        display: none !important;
    }

    .agencyNameTool {
        align-self: center;
        margin: auto;
    }
    
    .dashboard{
        display: flex !important;
        height: 100%;

        .innerSide {
            height: 300px;
            width: 100%;
            text-align: center;
        }
        .side1 {
            width: 10%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #E6E6E6;
        }

        .side2 {
            width: 32%;

            .active {
                width: 100%;
                background-color: #f6f6f6;
            }
            .square{
                height: 20px;
            }
        }

        .side3 {
            box-shadow: -3.39694px 0px 27.1756px rgba(128, 128, 128, 0.15);
            width: 100%;

            .dynamic {
                display: flex;
                flex-direction: row;
            }
            .circleAgency {
                width: 40px;
                height: 40px;
                background-color: #f6f6f6;
                border-radius: 50px;
            }
        }

        .icons {
            margin: auto;
            padding: 15px 0px 15px 0px;
        }

        .image {
            width: 80%;
            margin: auto;
        }
    }

    .headingH2 {
        display: none;
    }
    .onBordingFirstFont {
        display: none;
    }
    .headingH4 {
        display: none;
    }
    .col {
        height: 80vh;
        text-align: left !important;
        padding-right: 0px;
        border-radius: 24px;
        padding-left: 0px;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        transition: box-shadow 0.7s ease-in-out;
    }

    .toolbar {
        display: flex !important;
        flex-direction: row;
        height: 40px;
        width: 100%;
        background-color: #E9ECEF;
        grid-gap: 0px;
    }
  
    .circleTool {
        width: 16px;
        height: 16px;
        background-color: #9DA7AE;
        border-radius: 50%;
        margin-left: 20px;
        align-self: center;
    }
}

#brandNameScreen{
    width: 100%;
    padding: none;

    .makeStyles-root-3{
        padding: 0;
    }

    .cards{
        margin: auto;
        margin-top: 20%;
        margin-left: 20%;
        width: 65%;
    }

    .customInputField{
        width: 100%;
    }
    
    //inputfield

    .MuiInputBase-input MuiInput-input makeStyles-root-6{
        padding: 0;
    }

    .makeStyles-root-6 {
        padding: 0px !important;
        padding-bottom: 10px !important;
    }

}

#brandLogoScreen{ 
    width: 100%;
    padding: none;
    z-index: 999;

    .cards{
        margin: auto;
        margin-top: 20%;
        margin-left: 20%;
        height: 100%;
        width: 75%;

    }

    .contents{
        display: flex;
        flex-direction: row;
    }

    .dropImageCircle{
        width: 152px;
        height: 152px;
        margin: auto;
        border: 1px dashed $placeholder;
        box-sizing: border-box;
        border-radius: 50%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: background-color ease .7s;
        &:hover{
            &.image{
                background-color: rgba(0,0,0,.6);
            }
            .imageUpload{
                opacity: 1;
            }
        }
    }

    .imageUpload{
        transition: opacity ease .7s;
    }

    .imageUpload.remove{
        opacity: 0;
    }

    .insideImage{
        border-radius: 50%;
        width: inherit;
        height: inherit;
        position: absolute;
        z-index: -1;
    }

    .box1{
        justify-content: center;
        width: 35%;
        border-right: 1px solid #F0F0F0;
    }

    .box2{
        width: 65%;
        text-align: center;
    }

    .caption{
        color: #000;
    }

    .circleRow{
        flex: 0 0 10.333333%;
        max-width: 10.333333%;
        padding-bottom: 7%;
    }

    .colorCircle{
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .selectedColor{
        transform: scale(1.4);
        transition: ease 0.3s;
    }

    .nextBtn{
        background-color: $Secondary;
        color: white;
        width: 40%;
        font-size: 14px;
        height: 80%;
    }
    
    .pallette{
        width: 237px !important;
    }

}


#brandCoverScreen{
    width: 100%;
    padding: none;
    z-index: 999;

    .cards{
        margin: auto;
        margin-top: 20%;
        margin-left: 20%;
        height: 100%;
        width: 75%;

    }

    .contents{
        display: flex;
        flex-direction: row;
    }

    .dropImageCircle{
        width: 100%;
        height: 152px;
        margin: auto;
        border: 1px dashed $placeholder;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: background-color ease .7s;
        &:hover{
            &.image{
                background-color: rgba(0,0,0,.6);
            }
            .imageUpload{
                opacity: 1;
            }
        }
    }

    .imageUpload{
        transition: opacity ease .7s;
    }

    .imageUpload.remove{
        opacity: 0;
    }

    .insideImage{
        width: inherit;
        height: inherit;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
    }

    .box1{
        justify-content: center;
        width: 35%;
        border-right: 1px solid #F0F0F0;
    }

    .box2{
        width: 65%;
        text-align: center;
    }

    .caption{
        color: #000;
    }

    .circleRow{
        flex: 0 0 10.333333%;
        max-width: 10.333333%;
        padding-bottom: 7%;
    }

    .colorCircle{
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .selectedColor{
        transform: scale(1.4);
        transition: ease 0.3s;
    }

    .nextBtn{
        background-color: $Secondary;
        color: white;
        width: 40%;
        font-size: 14px;
        height: 80%;
    }
    
    .pallette{
        width: 237px !important;
    }


}


#serviceTypeScreen{
    width: 100%;
    padding: none;

        .caption{
            color: #000;
        }

        
        .headingH3{
            line-height: 48px;
            font-weight: 300;
        }

        .cards{
            margin: auto;
            margin-top: 20%;
            margin-left: 20%;
            width: 70%;
        }

        .singleServiceType{
            height: 40px;
            min-width: 100%;
            border-radius: 8px;
            padding: 8px 24px 8px 24px;
            border-radius: 8px;
            border: 1px solid $headline3;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            box-sizing: border-box;
            color: $c828282;
            background-color: white;

        }

        .singleServiceType:hover{
            border: 1px solid $themeColor;
            color: $themeColor;
        }

        .active{
            border: 1px solid $themeColor;
            color: $themeColor;
        }


}


#onlineService{
    width: 100%;
    padding: none;

        .caption{
            color: #000;
        }

        
        .headingH3{
            line-height: 48px;
            font-weight: 300;
        }

        .cards{
            margin: auto;
            margin-top: 20%;
            margin-left: 20%;
            width: 70%;
        }

        .singleServiceType{
            height: 70px;
            min-width: 100%;
            border-radius: 8px;
            padding: 8px 24px 8px 24px;
            border-radius: 8px;
            border: 1px solid $headline3;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            box-sizing: border-box;
            color: $c828282;
            background-color: white;

        }

        .singleServiceType:hover{
            border: 1px solid $themeColor;
            color: $themeColor;
        }

        .active{
            border: 1px solid $themeColor;
            color: $themeColor;
        }


}



#locationScreen{
    width: 100%;
    padding: none;

        .makeStyles-root-6 {
            padding: 0px !important;
            padding-bottom: 10px !important;
        }

        .caption{
            color: #000;
        }

        .headingH3{
            line-height: 48px;
            font-weight: 300;
        }

        .cards{
            margin: auto;
            margin-top: 17%;
            margin-left: 20%;
            width: 70%;
        }

        .makeStyles-root-3{
            padding: 0;
        }

        .customInputField{
            min-width: 100%;
        }

        .MuiInputLabel-shrink{
            color: $headline3;
        }
        
        .nextBtn{
            background-color: $Secondary;
            color: white;
            width: 128px;
            font-size: 14px;
            height: 80%;
        }

        .makeStyles-root-3{
            padding: 0;
        }

}




@media screen and (max-width: 812px){

    .OnBoardingPage{
        #SignupHeader .logoBox {
            display: block;
        }

        #connectBrand {
            transition: transform 0.8s;
            .cardMain {
                width: 100%;
                margin: auto;
            }
            .cardsRow{
                width: 100%;
            }
            .cards {
                width: 120px;
                height: 150px;
            }
            .cardsSel {
                width: 120px;
                height: 150px;
            }

            .circle {
                width: 40px;
                margin-top: 38px !important;
                height: 40px;
            }
        }
        
        .scaleClass {
            transform: translate(6%, 65%);
            background: none;
            z-index: -1 !important;
            overflow-x: hidden;


            .cardsRow {
                width: 80% !important;
                margin: auto;
            }
        }


        .mobileCol {
            width: 80% !important;
            margin: auto;
        }
    }
}