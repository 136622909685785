#tables{
    
    .tableBox{
        padding: 16px;
        box-shadow: none;
        border-radius: 16px;
    }

    .actionDropdown{
        background-color: $tableBackgroundColor;
        border-radius: 24px;
        width: 68px;
        height: 24px;
        padding: 0px;
        font-size: 14px;
    }
    
    .actionDropdown:focus{
        border-radius: 24px;
        width: 68px;
        height: 24px;
        outline: 0;
    }

    .actionField{
        padding: 0px;
        width: 100%;
    }
    .MuiInputBase-input .MuiInput-input .makeStyles-root-20 .MuiInputBase-inputAdornedStart{
        color: #2E3F52;
    }

    .searchField{
        border-radius: 10px;
    }

    
}