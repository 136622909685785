#brandDropdown{
    width: 270px;
    top: 45px;
    left: 30px;
    .allBrands{
        max-height: 200px;
        overflow-y: scroll;
    }

    .singleBrand{
        border-top: 1px solid $cF0F0F0;
        transition: background-color ease .3s;

        &:hover{
            background-color: $cF0F0F0;
        }
    }
}
