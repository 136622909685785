/* ---------------------------------------------------
    HELPERS SCSS
----------------------------------------------------- */

@import './helpers/fonts.scss';
@import './helpers/variables.scss';
@import './helpers/font-size.scss';
@import './helpers/font-wieght.scss';
@import './helpers/spacing.scss';
@import './helpers//color-scheme.scss';
@import './helpers/width.scss';
@import './helpers/opacity.scss';
@import './helpers/borders.scss';
@import './helpers/z-index.scss';



/* ---------------------------------------------------
    PAGES SCSS
----------------------------------------------------- */

@import './pages/main.scss';
@import './pages/side-stepper.scss';
@import './pages/dashboard.scss';
@import './pages/walletscan.scss';
@import './pages/setting/setting.scss';
@import './pages/userprofile/userprofile.scss';
@import './pages/auth/auth.scss';
@import './pages/auth/agency-onboarding.scss';
@import './pages/general.scss';
@import './pages/audience.scss';
@import './pages/lookandfeel.scss';

/* ---------------------------------------------------
SubPages
----------------------------------------------------- */


/* ---------------------------------------------------
    Components SCSS
----------------------------------------------------- */

@import './component/sidebar.scss';
@import './component/header.scss';
@import './component/email-verify.scss';
@import './component/cards.scss';
@import './component/signup-header.scss';
@import './component/referral-header.scss';
@import './component/tables.scss';
@import './component/sidebar-with-buttons.scss';
@import './component/custom-slider.scss';
@import './component/setting-header.scss';
@import './component//brands-dropdown.scss';
@import './component/toaster.scss';
@import './component/customtiercard.scss';
@import './component/custompass.scss';
@import './component/imagedropbox.scss';
@import './component/designdropdown.scss';
@import './component/viewdetail.scss';
@import './component/tagtextarea.scss';
@import './component/delete-modal.scss';
@import './component/custom-table.scss';
@import './component/customimagedrop.scss';

/* ---------------------------------------------------
    Global SCSS
----------------------------------------------------- */

body {
  margin                  : 0;
  padding                 : 0;
  font-family             : $poppins;
  color                   : $c828282;
  font-size               : 16px;
  font-weight             : 400;
  overflow-x              : hidden; 
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
  scroll-behavior         : smooth;
  line-height             : normal;
}

.strikeThrough{
  text-decoration: line-through;
}

.middle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cp{
  cursor : pointer
}

.cn{
  cursor : none;
}

.cd{
  cursor: default;
}

.bg-transparent {
  background: transparent;
}

.disabled{
  cursor: default;
  pointer-events: none;
  opacity: 0.3;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-dec-none{
  text-decoration: none !important;
}

.pe-none{
  pointer-events : none;
}

a.hover-none:hover{
  color: inherit;
}

.w-fit{
  width : fit-content;
}


.overlay {
  display    : none;
  position   : fixed;
  width      : 100vw;
  height     : 100vh;
  background : rgba(0, 0, 0, 0.7);
  z-index    : 998;
  opacity    : 0;
  top        : 0;
  transition : all 0.5s ease-in-out;
}

.display-none{
  display: none;
}

.content {
  width         : 100%;
  // overflow-y    : scroll;
  height        : 100vh;
  // z-index       : 1;
  z-index       : 1;
  padding-top   : 0px;
  padding-left  : 0px;
  padding-right : 0px;
  overflow-x    : hidden;
  background    : $cffffff;
  box-shadow: -2px 0px 8px rgba(148, 148, 184, 0.15);
  // border-radius : 24px 0px 0px 24px;
}

.dropdown-box{
  border: 1px solid $cF0F0F0;
  border-radius: 8px;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  transition: opacity ease .7s;
  max-height: 250px;
  overflow-y: scroll;
}

.grayed-image{
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.togglebtn {
  cursor : pointer;
  
}
label{
  font-size : 16px !important;
}

.bar{
  border-bottom: 0.5px solid $dividerColor !important;
  width: 100%;
  z-index: 0;
  left: 0px;
}

.z-index--1{
  z-index: -1;
}

.z-index-1{
  z-index: 1;
}

.justify-content-flex-end{
  justify-content: flex-end;
}

.justify-content-space-between{
  justify-content: space-between;
}

.width-fit-content{
  width: fit-content;
}

.uppercase{
  text-transform: uppercase;
}

.cursor-wait{
  cursor: wait;
}

#customRefresh{
  .imageButton{
    border-radius    : 50%;
  }
  .imageButton:hover{
      box-shadow       : 0px 0px 0px 7px #F0F1F3;
      background-color : #F0F1f3;
      border-radius    : 50%;
      transition       : box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding-top: 2px;
}

.tabBar{
  border-bottom: 2px solid $dividerColor !important;
  width: 100%;
  z-index: 0;
  left: 0px;
}

.grid-gap-10{
  grid-gap: 10px;
}

.secondary-button-border{
  border: 0.5px solid #2F80ED;
}

.saveAndCloseBtn:focus{
  outline: none;
}

.UploadImage{
  border: 1px dashed $c828282;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  width: 144px;
  color: $c828282;
  font-size: 12px;
}

.space-between{
  justify-content: space-between;
}

.brandId{
  padding : 8px 16px 8px 16px;
  border-radius: 32px;
  height: 40px;
  width: fit-content;
  margin-top: 24px;
}


#CopyText .text-wraper {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 250px;
}

.border-none{
  border: 0px !important;
}

.r-0{
  right: 0;
}

.justify-flex-end{
  justify-content: flex-end;
}

.align-flex-end{
  align-items: flex-end;
}

.accordinIcon{
  margin-top: -2px !important;
}

.accordionBorder{
  border: 1px solid $accordionBorderColor;
}

.capitalize{
  text-transform: capitalize;
}

.resize-none{
  resize: none;
}

.customLabel{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color : $textFieldLabelColor,
}

.vertical-align-super{
  vertical-align: super;
}

.vertical-align-bottom{
  vertical-align: text-bottom;
}

.rotate-180{transform: rotate(180deg)}
.rotate{transform: rotate(270deg)}

.align-items-center{
  align-items: center;
}

//used to fix the shadow of sidebar, this class is added in sidebarNav class
.handleSiderBarShadow{
  z-index: 3 !important; 
}

.center-align{
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
}


.absoluteMiddle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.h-inherit{
  height: inherit;
}

.h-100{
  height: 100%;
}


.loaderOuterPage{
  height: calc(100vh);
}

.content {
  .loaderOuterPage{
    height: calc(100vh - 60px);
  }
}

.row-reverse{
  flex-direction: row-reverse;
}


.text-decoration-line{
  text-decoration: line-through;
}


/* ---------------------------------------------------
    New Font styling
----------------------------------------------------- */

.headingH3 {
  font-size   : 24px;
  font-weight : 600;
  font-style  : normal;
  line-height : 32px;
  color       : $headline3;
}

.headingH2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: $cffffff;
}

.subtitle2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $subtitle;
  opacity: 0.6;
  white-space: pre-line;
}

.overline2{
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
}

.subtitle1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $subtitle;
}

.caption{
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.textsmall{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.textheading{
  font-weight: 400;
  font-size: 28px;
}

.textsubHeading{
  font-weight: 400;
  font-size: 24px;
}

.display-none{
  display: none !important;
}

.place-self-flex-end{
  place-self: flex-end;
}

.text-align-webkit-center{
  text-align: -webkit-center;
}

.white-space-no-wrap{
  white-space: nowrap;
}

.textsubheading{
  font-weight: 400;
  font-size: 14px;
}

.textHeading{
  font-weight: 400;
  font-size: 48px;
}

.buttonText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important; 
  line-height: 24px;
}

.body2 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: normal;
  color: $c2E3F52;

}

.body1{
  font-size: 16px;
  font-weight: 400px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: $c2E3F52;
}


.subheading{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: $headline3;
}



.onBordingFirstFont{
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
}

.headingH4 {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  line-height: 24px !important;
}


.question {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
}

.headline4{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $c4F4F4F;
}

.headline3{
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $c4F4F4F;
}

.headline2{
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
  text-align: center;
  color: $textfieldColor;
}

.error{
  color: $error;
  text-align: left;
  margin: 0 14px;
  margin-top: 3px;
  font-size: 1.1rem;
}


//new Fields 


.paragraph {
  font-size   : 14px;
  font-weight : 400;
}

.navigationHeading{
  font-size: 20px;
  font-weight: 500;
}

.headerHeading{
  font-size: 18px;
  font-weight: 500;
}

.text-align-right{
  text-align: right;
}

.heading3{
  font-size: 18px;
  font-weight: 600;
}

.heading4{
  font-size: 16px;
  font-weight: 600;
}

.align-self-center{
  align-self: center;
}

.text-align-center{
  text-align: center;
}

.navLinks{
  font-size: 13px;
  font-weight: 500;
}

.buttonText{
  font-size: 14px;
  font-weight: 500;
}

.links2{
  font-size: 12px;
  font-weight: 500;
}

.link3{
  font-size: 14px;
  font-weight: 500;
}

.tabSelected{
  font-size: 14px;
  font-weight: 600;
}

.tableFiled{
  font-size: 12px;
  font-weight: 400;
}

.mt--5{
  margin-top: -5px;
}

.tableLinkField{
  font-weight: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.underline{
  text-decoration: underline;
}



/* ---------------------------------------------------
    Media Queries
----------------------------------------------------- */

/*  For Sidebar */
@media(max-width: 767px){
  #sidebar {  

    height    : 100vh;
    min-width : 0px;
    z-index   : 1;
  }
  .content {
    width         : 100%;
    // padding-top   : 40px;
    // padding-left  : 28px;
    // padding-right : 28px;
    background    : $cffffff;
    z-index       : 3 !important;
    box-shadow    : -4px 4px 20px rgba(100, 100, 100, 0.1);
    border-radius : 24px 0px 0px 24px;
  }

  .heading {
    font-size   : 30px;
    font-weight : bold;
    color       : $c808080;
  }

  .headingH1 {
    font-size   : 20px;
    font-weight : bold;
    color       : $c222222;
  }

  .headingH2 {
    font-size   : 16px;
    font-weight : bold;
    color       : $c262626;
  }

  .headingH4 {
    font-size   : 10px !important;
    font-weight : 500 !important;
  }

  // .paragraph {
  //   font-size   : 14px;
  //   font-weight : 400;
  // }

  .paragraphBold {
    font-size   : 10px;
    font-weight : 500;
    font-weight : normal;
    color       : $c717171;
  }



  .paragraphsm {
    font-size   : 8px;
    font-weight : normal;
    color       : $c4F4F4F;
  }

}

/* xs */
@media (max-width: 575px){
  body{
    font-size : 4vw;
  }
  .main-heading{
    font-size : 7vw;
  }
  
}
/* sm */
@media (min-width: 576px) and (max-width: 767px){

}
/* md */
@media (min-width: 768px) and (max-width: 991px){

}
/* lg */
@media (min-width: 992px) and (max-width: 1199px){

}
/* xl */
@media(min-width: 1200px){

}
/* xxl */
@media(min-width: 1600px){
  #Main {
    height : 100vh;
  }
  
}


iframe{
  display: none !important;
}