#CoverImageDrop{
    .inputImageBox{
        border: 1px solid $themeColor;
        border-style: dashed;
        box-sizing: border-box;
        overflow: hidden;
        height: 147px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropImageCircle{
        width: 100%;
        // height: 152px;
        height: 92px;
        margin: auto;
        border: 1px dashed $placeholder;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: background-color ease .7s;
        &:hover{
            &.image{
                background-color: rgba(0,0,0,.6);
            }
            .imageUpload{
                opacity: 1;
                z-index: 100;
                background-color: rgba(0,0,0,.6);

            }
        }
    }

    .dropImageCircle:hover{
        background-color: rgba(0,0,0,.6);

    }

    .imageUpload{
        transition: opacity ease 0.7s;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .imageUpload.remove{
        opacity: 0;
    }

    .insideImage{
        width: inherit;
        height: inherit;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
    }
}