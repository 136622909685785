#Auth, #UserOnboard{
    padding-top: 40px;
    overflow-y: hidden;


    #PlanSettingScreen {
        .row{
            width: 100%;
            margin: auto;
            justify-content: center;
            grid-gap: 42px;
        }
        .card {
            background-color: #FFFFFF;
            border: 1px solid #F0F0F0;
            box-sizing: border-box;
            box-shadow: 0px 4px 30px rgba(82, 63, 105, 0.05);
        }
        .heading {
            font-style: normal;
            font-weight: 400;
            font-size: 40px;
            line-height: 48px;
            color: #273240;
        }

        .head {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: #273240;
        }

        .normal {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            .head{
                font-weight: 600;
            }
        }
        .perbrand {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 48px;

            .head{
                font-weight: 400;
                font-size: 16px;
            }

        }
        .btn-next{
            width: 158px;
            align-self: center;
            white-space: nowrap;
        }
    }


    #billing{
        .textfields {
            padding: 24px 24px 24px 24px;
        }

        .billinghead {
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            line-height: 60px;
        }
        .caption{
            margin-top: 0px;
        }

        .selectedPlan {
            padding: 24px;
            width: 45%;
            margin: auto;
            border: 1px solid #1274E0;
        }
    }
    

    .makeStyles-root-11 {
        font-size: 0.8rem !important;
    }

    .bullets {
        width: 100%;
        margin: auto;
        margin-top: 16px;

        .row {
            width: 100%;
            margin: auto;
        }
    }

    .card {
        max-width: 472px;
        border-radius: 10px;
        padding: 40px 0px 0px 0px;
        margin: auto;
        height: 100%;
        border: 1px solid #F0F0F0;;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
    }
    .formBody{
        width: 400px;
        padding: 0px;
        order: 1;
        flex-grow: 0;
        margin:auto;
    }

    .input {
        margin: auto;   
    }

    .inputTextField , .makeStyles-root-4 {
        width: 100%;
        // border-color:  $c828282;
    }

     .caption{
         margin-top: 40px;
     }

     .createAccountLink{
         color: $c2F80ED;
         font-weight: 400;
         text-decoration: underline;
     }
     
     .createAccountLink:hover{
        color: $c2F80ED;
     }

     .termsandprivacy{
         color: $c2D9CDB !important;
     }

     .termsandprivacy:hover{
        color: $c2D9CDB !important;
    }

    //New css for other components in loginSignup

    .headingH2{
        color: $c4F4F4F;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.25px;
        font-weight: 400;
    }

    .subtitle1{
        color: $c4F4F4F;
    }

    .subtitle2{
        color: $c828282;
    }

    .footer{
        margin-bottom: 0;
        padding: 32px 8px 32px 8px;
        border-radius: 8px;
        width: 100%;
    }

    .signUpFooter{
        margin-bottom: 0;
        padding: 32px 0px 32px 0px;
        border-radius: 8px;
        width: 100%;
        background-color: $cFAFAFA;
    }

    //step1 page signup css

    #stepOne{
        justify-content: center;
        width: 96%;
        margin: auto;
    }

    .stepCount{
        width: fit-content;
        height: 36px;
        color: white;
        background-color: $c4F4F4F;
        padding: 8px 16px 8px 16px;
        border-radius: 56px;
        letter-spacing: 0.25px;
        font-size: 14px;
        line-height: 20px;
        margin-top: -60px;
    }

    .countrycode{
        position: absolute;
        bottom: 0px;
        border-radius: 8px;
        height: 56px;
        width: 130px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    .question{
         color: $c828282;
         font-weight: normal;
         font-size: 34px;
         line-height: 20px;
     }

     .singleJobType{
        height: 40px;
        width: 100%;
        border-radius: 8px;
        padding: 8px 24px 8px 24px;
        border-radius: 8px;
        border: 1px solid $c828282;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 14px;
        box-sizing: border-box;
        color: $c828282;
        background-color: white;

     }

     .singleJobType:focus , .singleCompanyPeople:focus, .singleExperience:focus{
         outline: none;
     }

     .singleJobType:hover , .singleCompanyPeople:hover , .singleExperience:hover{
        border: 1px solid $c2F80ED;
        color: $c2F80ED;
    }

    .active{
        border: 1px solid $c2F80ED !important;
        color: $c2F80ED !important;
    }

    .singleCompanyPeople{
        height: 40px;
        min-width: 100%;
        border-radius: 8px;
        padding: 8px 24px 8px 24px;
        border-radius: 8px;
        border: 1px solid $c828282;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 14px;
        box-sizing: border-box;
        color: $c828282;
        background-color: white;
    }

    .singleExperience{
        height: 50px;
        min-width: 100%;
        padding: 0;
        border-radius: 8px;
        border-radius: 8px;
        border: 1px solid $c828282;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 14px;
        box-sizing: border-box;
        color: $c828282;
        background-color: white;
        letter-spacing: 0.15px;
        text-align: center;
    }

    .experienceBox{
        display: inline;
    }

    .learnMoreLink{
        color: $c2D9CDB !important;
        font-weight: 400;
    }

    .signupGif{
        width: 242px;
        height: 242px;
        position: fixed;
        right: 24px;
        bottom: 0px;
    }

    .uTextField{
        padding-top: 8px;
    }


    @media screen and (max-width: 812px) {
        .signUpFooter{
            padding: 5px;
        }

    }

    @media screen and (max-width: 1012px) {
        .signupGif{
            display: none;
        }

        .card{
            max-width: 760px;
        }

        .singleJobType{
            padding: 0px;
        }
    }
    
}