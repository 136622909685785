#profile{
    .component{
        padding: 34px 20px 24px 24px;
        width: 100%;
    }
    .inputfile {
        display: none !important;
    }
    
    .customFileUpload{
        padding: 8px;
        color: $textfieldColor;
    }

}


#ProfileSub{
    .bottom{
        // height: 24px;
        // background-color: grey;
        // position:absolute; 
        // bottom:0px;
        // width:100%; 
        // display: flex;
        // justify-content:center; 
        // flex-direction:column; 
        // align-items:center;
        // opacity: 1.5;
    }
    .bottom-parent{
        // height: 60px; 
        // width:60px; 
        // border-radius:50%;
        // overflow:hidden;
    }
}