@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/poppins/Poppins-Bold.ttf');
}
